#react-paginate ul {
    display: inline-block;
    margin-left: 20px;
    padding-left: 0;
 }
 
 #react-paginate li {
    display: inline-block;
    border: 1px solid rgb(224, 224, 224);
    color: #000;
    cursor: pointer;
    margin-right: 3px;
    border-radius: 5px;
    margin-bottom: 5px;
 }
 
  #react-paginate li a {
      padding: 2px 5px;
      display: inline-block;
      color: #000;
      outline: none;
  }
 
 #react-paginate li.active {
    background: rgb(224, 224, 224);
    outline: none;
 }
 
.table-form{
    margin: 10px 0px 10px 0px;
 }

 .table-text{
    margin: 0px 5px 0px 5px;
 }

/* #react-paginate ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
}

#react-paginate li {
    display: inline-block;
}


.pagination ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
}

.pagination li {
    display: inline-block;
} */
